import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { Advert, IAdvert } from '../../pages/adverts/models';
import { FireStoreService } from './firestore.service';

@Injectable()
export class AdvertService extends FireStoreService<IAdvert> {

    $adverts: BehaviorSubject<Array<IAdvert>> = new BehaviorSubject<Array<IAdvert>>([]);
    adverts: Array<IAdvert> = [];
    $currentAdvert: BehaviorSubject<IAdvert> = new BehaviorSubject<IAdvert>(null);

    constructor(_firestore: AngularFirestore) {
        super(_firestore, 'adverts');

        this.$adverts.subscribe(adObs => {
            this.adverts = adObs;
        });
    }

    getAllAdverts(): Observable<Array<IAdvert>> {
        this.getAll()
            .subscribe(ads => {
                this.$adverts.next(ads.map(ad => {
                    const advert = new Advert();
                    Object.assign(advert, ad.data);
                    advert.docId = ad.docId;
                    advert.publishDate = ad.data.publishDate?.toDate();
                    advert.createdDate = ad.data.createdDate.toDate();
                    return advert;
                }));
            });

        return this.$adverts;
    }

    getAdvert(id: string): Observable<IAdvert> {
        const advert = new Advert();
        const match = this.adverts.find(ad => {
            return ad.docId === id;
        });

        if (!match) {
            this.getDoc(id).subscribe(doc => {
                Object.assign(advert, doc);
                advert.docId = doc.docId;
                advert.publishDate = doc.publishDate?.toDate();
                advert.createdDate = doc.createdDate.toDate();
                this.$currentAdvert.next(advert);
            });
        } else {
            Object.assign(advert, match);
            this.$currentAdvert.next(advert);
        }

        return this.$currentAdvert;
    }

    update(advert: IAdvert): Promise<boolean> {
        return this.updateDoc(advert.docId, advert).then(() => {
            return true;
        }, error => {
            return false;
        });
    }

    create(advert: IAdvert): Promise<IAdvert> {
        return this.createDoc(advert);
    }

    delete(advert: IAdvert): Promise<boolean> {
        return this.deleteDoc(advert.docId)
            .catch(err => {
                console.error(err);
                return false;
            })
            .then(() => {
                return true;
            });
    }
}
