import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable, BehaviorSubject } from "rxjs";
import { FireStoreService } from "./firestore.service";
import { Organisation, IOrganisation as IOrganisation } from "../../pages/organisations/models/organisation.model";

@Injectable()
export class OrganisationService extends FireStoreService<IOrganisation> {
  $organisations: BehaviorSubject<Array<IOrganisation>> = new BehaviorSubject<Array<IOrganisation>>([]);
  organisations: Array<IOrganisation> = [];
  $currentOrganisation: BehaviorSubject<IOrganisation> = new BehaviorSubject<IOrganisation>(
    null
  );

  constructor(_firestore: AngularFirestore) {
    super(_firestore, "organisations");

    this.$organisations.subscribe((organisationObs) => {
      this.organisations = organisationObs;
    });
  }

  getAllOrganisations(): Observable<Array<IOrganisation>> {
    this.getAll().subscribe((result) => {
      const formattedOrganisations = result.map((d) => {
        const organisation = new Organisation();
        Object.assign(organisation, d.data);
        organisation.docId = d.docId;
        organisation.createdDate = d.data.createdDate.toDate();
        organisation.updatedDate = d.data.updatedDate?.toDate();
        return organisation;
      });
      this.$organisations.next(formattedOrganisations);
    });

    return this.$organisations;
  }

  getOrganisation(id: string): Observable<IOrganisation> {
    const match = this.organisations.find((d) => {
      return d.docId === id;
    });

    if (!match) {
      this.getDoc(id).subscribe((doc) => {
        const organisation = new Organisation();
        Object.assign(organisation, doc);
        organisation.docId = id;
        organisation.createdDate = doc.createdDate.toDate();
        organisation.updatedDate = doc.updatedDate?.toDate();
        this.$currentOrganisation.next(organisation);
      });
    } else {
      this.$currentOrganisation.next(match);
    }

    return this.$currentOrganisation;
  }

  update(organisation: IOrganisation): Promise<boolean> {
    return this.updateDoc(organisation.docId, organisation).then(
      () => {
        return true;
      },
      (error) => {
        console.error(error);
        return false;
      }
    );
  }

  async create(organisation: IOrganisation): Promise<IOrganisation> {
    var createdDoc = await this.createDoc(organisation);
    organisation.docId = createdDoc.docId;
    
    return organisation;
  }

  delete(organisation: IOrganisation): Promise<boolean> {
    return this.deleteDoc(organisation.docId)
      .catch((err) => {
        console.error(err);
        return false;
      })
      .then(() => {
        return true;
      });
  }
}
