import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { FireStoreService } from './firestore.service';
import { IProductCategory } from '../../pages/product-categories/models';
import _ from 'lodash';

@Injectable()
export class ProductCategoryService extends FireStoreService<IProductCategory> {

    $productCategories: BehaviorSubject<Array<IProductCategory>> = new BehaviorSubject<Array<IProductCategory>>([]);
    productCategories: Array<IProductCategory> = [];
    $currentProductCategory: BehaviorSubject<IProductCategory> = new BehaviorSubject<IProductCategory>(null);

    constructor(_firestore: AngularFirestore) {
        super(_firestore, 'productCategories');

        this.$productCategories.subscribe(productCategoryObs => {
            this.productCategories = productCategoryObs;
        });
    }

    getAllProductCategories(): Observable<Array<IProductCategory>> {
        this.getAll()
            .subscribe(result => {
                this.$productCategories.next(result.map(pcs => {
                    const productCategory = pcs.data;
                    productCategory.id = pcs.docId;
                    productCategory.createdDate = productCategory.createdDate.toDate();
                    return productCategory;
                }));
            });

        return this.$productCategories;
    }

    getProductCategory(id: string): Observable<IProductCategory> {
        const match = this.productCategories.find(man => {
            return man.id === id;
        });

        if (!match) {
            this.getDoc(id).subscribe(doc => {
                this.$currentProductCategory.next(doc);
            });
        } else {
            this.$currentProductCategory.next(match);
        }

        return this.$currentProductCategory;
    }

    update(productCategory: IProductCategory): Promise<boolean> {
        this.setSubCategoryIds(productCategory.subCategories);
        return this.updateDoc(productCategory.id, productCategory).then(() => {
            return true;
        }, error => {
            return false;
        });
    }

    create(productCategory: IProductCategory): Promise<any> {
        this.setSubCategoryIds(productCategory.subCategories);

        return this.createDoc(productCategory);
    }

    delete(productCategory: IProductCategory): Promise<boolean> {
        return this.deleteDoc(productCategory.id)
            .catch(err => {
                console.error(err);
                return false;
            })
            .then(() => {
                return true;
            });
    }

    private setSubCategoryIds(subCategories: Array<IProductCategory>) {
        subCategories.filter(sub => _.isEmpty(sub.id)).forEach(sub => {
            sub.id = this.generateId();
        });
    }
}
