import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { AuthModule } from "./auth/auth.module";
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth/login']);

// {
//   path: 'auth',
//   component: NbAuthComponent,
//   children: [
//     {
//       path: '',
//       component: NbLoginComponent,
//     },
//     {
//       path: 'login',
//       component: NbLoginComponent,
//     },
//     {
//       path: 'register',
//       component: NbRegisterComponent,
//     },
//     {
//       path: 'logout',
//       component: NbLogoutComponent,
//     },
//     {
//       path: 'request-password',
//       component: NbRequestPasswordComponent,
//     },
//     {
//       path: 'reset-password',
//       component: NbResetPasswordComponent,
//     },
//   ],
// },

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => AuthModule
  },
  {
    path: '', 
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  { path: '**', redirectTo: 'pages' },
];


const config: ExtraOptions = {
  useHash: false,
  // enableTracing: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
