import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { FireStoreService } from './firestore.service';
import _ from 'lodash';

@Injectable()
export class IndustryService extends FireStoreService<IIndustriesDoc> {

    $industries: BehaviorSubject<IIndustriesDoc> = new BehaviorSubject<IIndustriesDoc>( {values: []});
    industries: IIndustriesDoc = null;

    constructor(_firestore: AngularFirestore) {
        super(_firestore, 'lookupData');

        this.$industries.subscribe(industriesObs => {
            this.industries = industriesObs;
        });
    }

    getAllIndustries(): Observable<IIndustriesDoc> {
        this.getDoc('industries')
            .subscribe(result => {
                this.$industries.next(result);
            });

        return this.$industries;
    }

    update(industries: IIndustriesDoc): Promise<boolean> {
        return this.updateDoc('industries', industries).then(() => {
            console.log('Update success')
            return true;
        }, error => {
            return false;
        });
    }
}


export class IIndustriesDoc {
    values: Array<string>;
}