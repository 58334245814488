import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NbLoginComponent } from '@nebular/auth';
@Component({
  selector: 'ei-unauthorized',
  styleUrls: ['./unauthorized.component.scss'],
  templateUrl: './unauthorized.component.html',
})
export class UnauthorizedComponent  {

    constructor(private _router: Router) {
        
    }
    logout() {
        this._router.navigate(["auth/logout"]);
    }
}