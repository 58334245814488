import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class FireStoreService<T> {
  collectionName: string;
  constructor(private _firestore: AngularFirestore, collectionName: string) {
    this.collectionName = `environments/${environment.environmentName}/${collectionName}`;
    console.log(this.collectionName);
  }

  generateId() {
    return this._firestore.createId();
  }

  getAll(): Observable<Array<any>> {
    return this._firestore.collection(this.collectionName).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const docId = a.payload.doc.id;
        return { docId, data };
      })),
    );
  }

  getDoc(id) {
    return this._firestore.doc<any>(`${this.collectionName}/${id}`).valueChanges();
  }

  getAsMapped(id): Observable<T>{
    return this._firestore.doc<T>(`${this.collectionName}/${id}`).valueChanges();
  }

  updateDoc(docId: string, doc: T): Promise<void> {
    var updatedObj = Object.assign({}, doc);
    return this._firestore.collection(this.collectionName).doc<T>(docId).update(updatedObj);
  }

  createDoc(doc: T): Promise<T> {
    return this._firestore
      .collection(this.collectionName)
      .add({ ...doc })
      .then((docRef: DocumentReference) => {
        return docRef.get().then(snap => {
          const returnDoc = { ...snap.data() };
          returnDoc.docId = docRef.id;
          return <T>returnDoc;
        });
      });
  }

  deleteDoc(docId: string): Promise<void> {
    return this._firestore.collection(this.collectionName).doc(docId).delete();
  }
}
