import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { NbAuthComponent, NbLogoutComponent } from '@nebular/auth';
import { NotFoundComponent } from '../pages/miscellaneous/not-found/not-found.component';
import { EiLoginComponent } from './login/login.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
const redirectLoggedInToHome = () => redirectLoggedInTo(['/']);
export const routes: Routes = [
    {
        path: '',
        component: NbAuthComponent,
        children: [
            {
                path: 'login',
                component: EiLoginComponent,
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: redirectLoggedInToHome },
            },
            {
                path: 'logout',
                component: NbLogoutComponent,
            },
            {
                path: 'unauthorized',
                component: UnauthorizedComponent
            },
            {
                path: '**',
                component: NotFoundComponent,
            },
        ],
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {
}