<div class="row">
    <div class="col-md-12">
      <nb-card>
        <nb-card-body>
          <div class="flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12">
            <h2 class="title">You are unauthorized to access this site.</h2>
            <small class="sub-title">If you believe you should be able to access this site. Please contact Engineers Insight</small>
            <button nbButton matRipple fullWidth (click)="logout()" type="button" class="home-button">
              Logout
            </button>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
  