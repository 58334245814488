export interface IProductAnalytics {
  analyticEvents: Array<IAnalyticEvent>;
}

export interface IAnalyticEvent {
  docId: string;
  event_name: string;
  eventTime: Date;
  manufacturer: string;
  name: string;
  user_id: string;
}

export class AnalyticEvent {
  docId: string;
  event_name: string;
  eventTime: Date;
  manufacturer: string;
  name: string;
  user_id: string;

  constructor(){};
}
