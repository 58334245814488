import { IManufacturer } from './../../pages/manufacturers/models/manufacturer.model';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { FireStoreService } from './firestore.service';

@Injectable()
export class ManufacturerService extends FireStoreService<IManufacturer> {

    $manufacturers: BehaviorSubject<Array<IManufacturer>> = new BehaviorSubject<Array<IManufacturer>>([]);
    manufacturers: Array<IManufacturer> = [];
    $currentManufacturer: BehaviorSubject<IManufacturer> = new BehaviorSubject<IManufacturer>(null);

    constructor(_firestore: AngularFirestore) {
        super(_firestore, 'manufacturers');

        this.$manufacturers.subscribe(manufacturerObs => {
            this.manufacturers = manufacturerObs;
        });
    }

    getAllManufacturers(): Observable<Array<IManufacturer>> {
        this.getAll()
            .subscribe(result => {
                this.$manufacturers.next(result.map(mans => {
                    const manufacturer = mans.data;
                    manufacturer.id = mans.docId;
                    manufacturer.createdDate = manufacturer.createdDate.toDate();
                    return manufacturer;
                }));
            });

        return this.$manufacturers;
    }

    getManufacturer(id: string): Observable<IManufacturer> {
        const match = this.manufacturers.find(man => {
            return man.id === id;
        });

        if (!match) {
            this.getDoc(id).subscribe(doc => {
                this.$currentManufacturer.next(doc);
            });
        } else {
            this.$currentManufacturer.next(match);
        }

        return this.$currentManufacturer;
    }

    update(manufacturer: IManufacturer): Promise<boolean> {
        return this.updateDoc(manufacturer.id, manufacturer).then(() => {
            return true;
        }, error => {
            console.error(error);
            return false;
        });
    }

    create(manufacturer: IManufacturer): Promise<any> {
        return this.createDoc(manufacturer);
    }

    delete(manufacturer: IManufacturer): Promise<boolean> {
        return this.deleteDoc(manufacturer.id)
            .catch(err => {
                console.error(err);
                return false;
            })
            .then(() => {
                return true;
            });
    }
}
