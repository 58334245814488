import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable, BehaviorSubject } from "rxjs";
import { FireStoreService } from "./firestore.service";
import { Content, IContent } from "../../pages/content/models";

@Injectable()
export class ContentService extends FireStoreService<IContent> {
  $contents: BehaviorSubject<Array<IContent>> = new BehaviorSubject<
    Array<IContent>
  >([]);
  contents: Array<IContent> = [];
  $currentContent: BehaviorSubject<IContent> = new BehaviorSubject<IContent>(
    null
  );

  constructor(_firestore: AngularFirestore) {
    super(_firestore, "content");

    this.$contents.subscribe((contentObs) => {
      this.contents = contentObs;
    });
  }

  getAllContent(): Observable<Array<IContent>> {
    this.getAll().subscribe((result) => {
      const formattedContent = result.map((c) => {
        const content = new Content();
        Object.assign(content, c.data);
        content.docId = c.docId;
        content.startDate = c.data.startDate.toDate();
        content.endDate = c.data.endDate?.toDate();
        content.createdDate = c.data.createdDate?.toDate();
        content.updatedDate = c.data.updatedDate?.toDate();
        return content;
      });
      this.$contents.next(formattedContent);
    });

    return this.$contents;
  }

  getContent(id: string): Observable<IContent> {
    const match = this.contents.find((c) => {
      return c.docId === id;
    });

    if (!match) {
      this.getDoc(id).subscribe((doc) => {
        const content = new Content();
        Object.assign(content, doc);
        content.docId = id;
        content.startDate = doc.startDate.toDate();
        content.endDate = doc.endDate?.toDate();
        content.createdDate = doc.createdDate?.toDate();
        content.updatedDate = doc.updatedDate?.toDate();
        this.$currentContent.next(content);
      });
    } else {
      this.$currentContent.next(match);
    }

    return this.$currentContent;
  }

  update(content: IContent): Promise<boolean> {
    return this.updateDoc(content.docId, content).then(
      () => {
        return true;
      },
      (error) => {
        console.error(error);
        return false;
      }
    );
  }

  async create(content: IContent): Promise<IContent> {
    var createdDoc = await this.createDoc(content);
    content.docId = createdDoc.docId;
    
    return content;
  }

  delete(content: IContent): Promise<boolean> {
    return this.deleteDoc(content.docId)
      .catch((err) => {
        console.error(err);
        return false;
      })
      .then(() => {
        return true;
      });
  }
}
