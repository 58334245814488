import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Created by <b><a href="https://www.engineersinsight.co.uk" target="_blank">Engineers-insight</a></b> 2020
    </span>
    <div class="socials">
      <a href="https://www.tiktok.com/@engineersinsight" target="_blank" style="color: white"><i class="fa-brands fa-tiktok"></i></a>
      <a href="https://www.instagram.com/engineersinsight/" target="_blank" style="color: white"><i class="fa-brands fa-instagram"></i></a>
    </div>
  `,
})
export class FooterComponent {
}
