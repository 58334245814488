export interface IOrganisation {
    docId: string;
    name: string,
    url: string;
    imageUrl: string; 
    createdDate: Date;
    updatedDate: Date;
    imageFilename: string;
    type: OrganisationType;
}

export class Organisation implements IOrganisation {
    docId: string;
    name: string = '';
    url: string;
    imageUrl: string;
    createdDate: Date;
    updatedDate: Date;
    imageFilename: string;
    type: OrganisationType = OrganisationType.recruitment;
}

export enum OrganisationType {
    recruitment
}
