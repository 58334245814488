import { PurchaseOption } from "./purchase-option.model";

export interface IAdvert {
    id: string;
    docId: string;
    manufacturer: string;
    manufacturerId: string;
    name: string;
    coverImageFilename: string;
    coverImageUrl: string;
    images: Array<string>;
    categoryId: string;
    subCategoryId: string;
    shortDescription: string;
    description: string;
    features: Array<string>;
    benefits: Array<string>;
    keywords: Array<string>;
    applications: string;
    relatedAdverts: Array<IAdvert>;
    documents: Array<any>;
    createdDate: Date;
    updatedDate: Date;
    isHorizontalCoverImage: boolean;
    publishDate: Date;
    published: boolean;
    isProductLaunch: boolean;
    youtubeVideoId: string;
    coverVideoUrl?: string; 
    purchaseOptions: Array<PurchaseOption>;
}

export class Advert implements IAdvert {
    id: string;
    manufacturer: string;
    manufacturerId: string;
    name: string;
    images: Array<string>;
    categoryId: string;
    subCategoryId: string;
    description: string;
    shortDescription: string;
    features: Array<string>;
    benefits: Array<string>;
    keywords: Array<string>;
    applications: string;
    relatedAdverts: Array<IAdvert>;
    documents: Array<any>;
    coverImageFilename: string;
    coverImageUrl: string;
    docId: string;
    createdDate: Date;
    updatedDate: Date;
    isHorizontalCoverImage: boolean;
    publishDate: Date;
    published: boolean = false;
    isProductLaunch: boolean;
    youtubeVideoId: string;
    coverVideoUrl?: string = null; 
    purchaseOptions: Array<PurchaseOption>;

    constructor() {
    }
}
