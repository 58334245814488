import { Component, OnInit, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'ngx-is-live-render',
  template: `
    <div class="tag-container" [ngClass]="tagClass">{{ status | uppercase }}</div>
  `,
  styleUrls: ['./is-live-render.component.scss'],
})
export class IsLiveRenderComponent implements ViewCell, OnInit {

  value: any;  // This will be the cell value (content status)
  rowData: any;  // This will be the entire row data

  status: string;
  tagClass: string;

  ngOnInit() {
    const today = new Date();
    const startDate = new Date(this.rowData.startDate);
    const endDate = this.rowData.endDate ? new Date(this.rowData.endDate) : null;

    if (!endDate) {
      if (startDate <= today) {
        this.status = 'Live';
        this.tagClass = 'tag-live';
      } else {
        this.status = 'Not yet active';
        this.tagClass = 'tag-not-yet';
      }
    } else {
      if (startDate <= today && today <= endDate) {
        this.status = 'Live';
        this.tagClass = 'tag-live';
      } else if (today < startDate) {
        this.status = 'Not yet active';
        this.tagClass = 'tag-not-yet';
      } else {
        this.status = 'Expired';
        this.tagClass = 'tag-expired';
      }
    }
  }
}
