import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { EiUser } from '../data/EiUser';
import { User } from '../data/users';
import { FireStoreService } from './firestore.service';

@Injectable()
export class UserService extends FireStoreService<EiUser> {
    $currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    constructor(_firestore: AngularFirestore) {
        super(_firestore, 'users');

    }
}
