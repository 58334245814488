export interface IContent {
    docId: string;
    name: string,
    type: ContentType;
    startDate: Date;
    endDate?: Date;
    url: string;
    imageUrl: string; 
    createdDate: Date;
    updatedDate: Date;
    imageFilename: string;
    isLive: boolean;  // Note: This will be a computed property, not stored directly
    height: number;
}

export class Content implements IContent {
    docId: string;
    name: string = '';
    type: ContentType;
    startDate: Date;
    endDate?: Date;
    url: string;
    imageUrl: string;
    createdDate: Date;
    updatedDate: Date;
    imageFilename: string;
    height: number;

    get isLive(): boolean {
        const today = new Date();
        if (!this.endDate) {
            return this.startDate <= today;
        }
        return this.startDate <= today && today <= this.endDate;
    }
}

export enum ContentType {
    Banner = "banner",
    None = ""
}
