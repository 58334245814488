import { Observable } from 'rxjs';

export interface Category {
  id: number;
  parentId?: number;
  order: number;
  name: string;
  children?: Category[];
}

export abstract class CategoryData {
  abstract getListData(): Observable<Category[]>;
}
