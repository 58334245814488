import { Category, CategoryData } from './../data/category';
import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';

@Injectable()
export class CategoryService extends CategoryData {

    private listData: Category[] = [
        {
            id: 1,
            order: 1,
            name: 'Building Technology',
            children: [
                {
                    id: 101,
                    parentId: 1,
                    order: 1,
                    name: 'Access Control',
                },
                {
                    id: 102,
                    parentId: 1,
                    order: 2,
                    name: 'Building Management',
                },
                {
                    id: 103,
                    parentId: 1,
                    order: 3,
                    name: 'Energy Management',
                },
                {
                    id: 104,
                    parentId: 1,
                    order: 4,
                    name: 'Fire Safety',
                },
                {
                    id: 105,
                    parentId: 1,
                    order: 5,
                    name: 'HVAC',
                },
                {
                    id: 106,
                    parentId: 1,
                    order: 6,
                    name: 'Security',
                },
                {
                    id: 107,
                    parentId: 1,
                    order: 7,
                    name: 'Software',
                },
            ],
        },
        {
            id: 2,
            order: 2,
            name: 'Critical Power, Cooling & Racks',
            children: [
                {
                    id: 201,
                    parentId: 2,
                    order: 1,
                    name: 'Cooling',
                },
                {
                    id: 202,
                    parentId: 2,
                    order: 2,
                    name: 'Data Centre Software',
                },
                {
                    id: 203,
                    parentId: 2,
                    order: 3,
                    name: 'Data Centre Modules',
                },
                {
                    id: 204,
                    parentId: 2,
                    order: 4,
                    name: 'IT Power Distribution',
                },
                {
                    id: 205,
                    parentId: 2,
                    order: 5,
                    name: 'Racks & Accessories',
                },
                {
                    id: 206,
                    parentId: 2,
                    order: 6,
                    name: 'Security & Environmental Monitoring',
                },
                {
                    id: 207,
                    parentId: 2,
                    order: 7,
                    name: 'Surge Protection & Power Conditioning',
                },
                {
                    id: 208,
                    parentId: 2,
                    order: 8,
                    name: 'Uninterruptible Power Supply (UPS)',
                },
            ],
        },
        {
            id: 3,
            order: 3,
            name: 'Drives, Softstarts & Motors',
            children: [
                {
                    id: 301,
                    parentId: 3,
                    order: 1,
                    name: 'Braking Resistors',
                },
                {
                    id: 302,
                    parentId: 3,
                    order: 2,
                    name: 'Converters',
                },
                {
                    id: 303,
                    parentId: 3,
                    order: 3,
                    name: 'Couplings',
                },
                {
                    id: 304,
                    parentId: 3,
                    order: 4,
                    name: 'Encoders',
                },
                {
                    id: 305,
                    parentId: 3,
                    order: 5,
                    name: 'Geared Motors',
                },
                {
                    id: 306,
                    parentId: 3,
                    order: 6,
                    name: 'Generators',
                },
                {
                    id: 307,
                    parentId: 3,
                    order: 7,
                    name: 'Induction Motors',
                },
                {
                    id: 308,
                    parentId: 3,
                    order: 8,
                    name: 'PM Motors',
                },
                {
                    id: 309,
                    parentId: 3,
                    order: 9,
                    name: 'Softstarts',
                },
                {
                    id: 310,
                    parentId: 3,
                    order: 10,
                    name: 'Inverters',
                },
            ],
        },
        {
            id: 4,
            order: 4,
            name: 'Energy',
            children: [
                {
                    id: 401,
                    parentId: 4,
                    order: 1,
                    name: 'Automation - Power Distribution',
                },
                {
                    id: 402,
                    parentId: 4,
                    order: 2,
                    name: 'Electric Car Charging',
                },
                {
                    id: 403,
                    parentId: 4,
                    order: 3,
                    name: 'Generators',
                },
                {
                    id: 404,
                    parentId: 4,
                    order: 4,
                    name: 'Grid Converters',
                },
                {
                    id: 405,
                    parentId: 4,
                    order: 5,
                    name: 'Power Quality & Measurement',
                },
                {
                    id: 406,
                    parentId: 4,
                    order: 6,
                    name: 'Power Factor Correction ',
                },
                {
                    id: 407,
                    parentId: 4,
                    order: 7,
                    name: 'Power Transformers',
                },
                {
                    id: 408,
                    parentId: 4,
                    order: 8,
                    name: 'Substation Automation',
                },
            ],
        },
        {
            id: 5,
            order: 5,
            name: 'Industrial Automation',
            children: [
                {
                    id: 501,
                    parentId: 5,
                    order: 1,
                    name: 'Communication Protocols',
                },
                {
                    id: 514,
                    parentId: 5,
                    order: 2,
                    name: 'CNC Machines',
                },
                {
                    id: 502,
                    parentId: 5,
                    order: 3,
                    name: 'Distributed IO',
                },
                {
                    id: 503,
                    parentId: 5,
                    order: 4,
                    name: 'HMI’s & IPC’s',
                },
                {
                    id: 504,
                    parentId: 5,
                    order: 5,
                    name: 'Motion Control & Servos',
                },
                {
                    id: 505,
                    parentId: 5,
                    order: 6,
                    name: 'PC-Based Controllers',
                },
                {
                    id: 506,
                    parentId: 5,
                    order: 7,
                    name: 'PLC Systems',
                },
                {
                    id: 507,
                    parentId: 5,
                    order: 8,
                    name: 'Power Supplies',
                },
                {
                    id: 513,
                    parentId: 5,
                    order: 9,
                    name: 'Remote Connection',
                },
                {
                    id: 508,
                    parentId: 5,
                    order: 10,
                    name: 'Robotics',
                },
                {
                    id: 509,
                    parentId: 5,
                    order: 11,
                    name: 'SCADA Systems',
                },
                {
                    id: 510,
                    parentId: 5,
                    order: 12,
                    name: 'Sensors & RFID',
                },
                {
                    id: 511,
                    parentId: 5,
                    order: 13,
                    name: 'Software',
                },
                {
                    id: 512,
                    parentId: 5,
                    order: 14,
                    name: 'Telemetry',
                },
            ],
        },
        {
            id: 6,
            order: 6,
            name: 'Instrumentation',
            children: [
                {
                    id: 601,
                    parentId: 6,
                    order: 1,
                    name: 'Pressure Measurement',
                },
                {
                    id: 602,
                    parentId: 6,
                    order: 2,
                    name: 'Temperature Measurement',
                },
                {
                    id: 603,
                    parentId: 6,
                    order: 3,
                    name: 'Flow Measurement',
                },
                {
                    id: 604,
                    parentId: 6,
                    order: 4,
                    name: 'Level Measurement',
                },
                {
                    id: 605,
                    parentId: 6,
                    order: 5,
                    name: 'Position Measurement',
                },
                {
                    id: 606,
                    parentId: 6,
                    order: 6,
                    name: 'Test Equipment',
                },
                {
                    id: 607,
                    parentId: 6,
                    order: 7,
                    name: 'Weight Measurement',
                },
            ],
        },
        {
            id: 7,
            order: 7,
            name: 'Low Voltage Products & Systems',
            children: [
                {
                    id: 701,
                    parentId: 7,
                    order: 1,
                    name: 'Busway & Cable Management',
                },
                {
                    id: 702,
                    parentId: 7,
                    order: 2,
                    name: 'Circuit Breakers & Switches',
                },
                {
                    id: 703,
                    parentId: 7,
                    order: 3,
                    name: 'Compressors',
                },
                {
                    id: 704,
                    parentId: 7,
                    order: 4,
                    name: 'Contactors & Relays',
                },
                {
                    id: 705,
                    parentId: 7,
                    order: 5,
                    name: 'Distribution & Grid Automation',
                },
                {
                    id: 706,
                    parentId: 7,
                    order: 6,
                    name: 'Enclosures',
                },
                {
                    id: 707,
                    parentId: 7,
                    order: 7,
                    name: 'Fuses & Safety Switches',
                },
                {
                    id: 708,
                    parentId: 7,
                    order: 8,
                    name: 'Isolators',
                },
                {
                    id: 709,
                    parentId: 7,
                    order: 9,
                    name: 'Motor Starters & Protection Components',
                },
                {
                    id: 710,
                    parentId: 7,
                    order: 10,
                    name: 'Pumps',
                },
                {
                    id: 711,
                    parentId: 7,
                    order: 11,
                    name: 'Pushbuttons, Switches, Pilot Lights & Joysticks',
                },
                {
                    id: 712,
                    parentId: 7,
                    order: 12,
                    name: 'Signalling',
                },
                {
                    id: 713,
                    parentId: 7,
                    order: 13,
                    name: 'Switchboards',
                },
                {
                    id: 714,
                    parentId: 7,
                    order: 14,
                    name: 'Timers',
                },
                {
                    id: 715,
                    parentId: 7,
                    order: 15,
                    name: 'Valves',
                },
            ],
        },
        {
            id: 8,
            order: 8,
            name: 'Mechanical Components',
            children: [
                {
                    id: 801,
                    parentId: 8,
                    order: 1,
                    name: 'Actuators',
                },
                {
                    id: 802,
                    parentId: 8,
                    order: 2,
                    name: 'Aluminium Extrusion',
                },
                {
                    id: 803,
                    parentId: 8,
                    order: 3,
                    name: 'Belts',
                },
                {
                    id: 804,
                    parentId: 8,
                    order: 4,
                    name: 'Bearings',
                },
                {
                    id: 805,
                    parentId: 8,
                    order: 5,
                    name: 'Bushings & Hubs',
                },
                {
                    id: 806,
                    parentId: 8,
                    order: 6,
                    name: 'Couplings',
                },
                {
                    id: 807,
                    parentId: 8,
                    order: 7,
                    name: 'Gearboxes',
                },
                {
                    id: 808,
                    parentId: 8,
                    order: 8,
                    name: 'Hydraulics',
                },
                {
                    id: 809,
                    parentId: 8,
                    order: 9,
                    name: 'Lubrication Systems',
                },
                {
                    id: 810,
                    parentId: 8,
                    order: 10,
                    name: 'Pneumatics',
                },
                {
                    id: 811,
                    parentId: 8,
                    order: 11,
                    name: 'Pulleys',
                },
            ],
        },
        {
            id: 9,
            order: 9,
            name: 'Medium Voltage Distribution',
            children: [
                {
                    id: 901,
                    parentId: 9,
                    order: 1,
                    name: 'Feeder Automation',
                },
                {
                    id: 902,
                    parentId: 9,
                    order: 2,
                    name: 'Grid Automation',
                },
                {
                    id: 903,
                    parentId: 9,
                    order: 3,
                    name: 'Medium Voltage Switchgear',
                },
                {
                    id: 904,
                    parentId: 9,
                    order: 4,
                    name: 'Medium Voltage Transformers',
                },
                {
                    id: 905,
                    parentId: 9,
                    order: 5,
                    name: 'Power Metering & Control',
                },
                {
                    id: 906,
                    parentId: 9,
                    order: 6,
                    name: 'Protection Relays',
                },
            ],
        },
        {
            id: 10,
            order: 10,
            name: 'Renewable Energy',
            children: [
                {
                    id: 1001,
                    parentId: 10,
                    order: 1,
                    name: 'Permanent Magnet Generators',
                },
                {
                    id: 1002,
                    parentId: 10,
                    order: 2,
                    name: 'Power Converters',
                },
                {
                    id: 1003,
                    parentId: 10,
                    order: 3,
                    name: 'Solar for Residential Buildings',
                },
                {
                    id: 1004,
                    parentId: 10,
                    order: 4,
                    name: 'Solar Off-Grid & Back-Up',
                },
            ],
        },
        {
            id: 11,
            order: 11,
            name: 'Residential & Commercial Buildings',
            children: [
                {
                    id: 1101,
                    parentId: 11,
                    order: 1,
                    name: 'Cable & Cable Management',
                },
                {
                    id: 1102,
                    parentId: 11,
                    order: 2,
                    name: 'Home Automation',
                },
                {
                    id: 1103,
                    parentId: 11,
                    order: 3,
                    name: 'Home Security',
                },
                {
                    id: 1104,
                    parentId: 11,
                    order: 4,
                    name: 'Light Switches',
                },
                {
                    id: 1105,
                    parentId: 11,
                    order: 5,
                    name: 'Lighting',
                },
                {
                    id: 1106,
                    parentId: 11,
                    order: 6,
                    name: 'Thermostats',
                },
            ],
        },
        {
            id: 12,
            order: 12,
            name: 'Safety',
            children: [
                {
                    id: 1201,
                    parentId: 12,
                    order: 1,
                    name: 'Laser Scanners',
                },
                {
                    id: 1202,
                    parentId: 12,
                    order: 2,
                    name: 'Light Curtains',
                },
                {
                    id: 1203,
                    parentId: 12,
                    order: 3,
                    name: 'Safety Controllers',
                },
                {
                    id: 1204,
                    parentId: 12,
                    order: 4,
                    name: 'Safety IO',
                },
                {
                    id: 1205,
                    parentId: 12,
                    order: 5,
                    name: 'Safety Relays',
                },
                {
                    id: 1206,
                    parentId: 12,
                    order: 6,
                    name: 'Safety Sensors',
                },
                {
                    id: 1207,
                    parentId: 12,
                    order: 7,
                    name: 'Safety Switches',
                },
            ],
        },
    ];

    getListData(): Observable<Category[]> {
        return observableOf(this.listData);
    }
}
